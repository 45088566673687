<template>
    <div class="commonWidth">
        <div class="timeList">
            <div class="timeListTitle">
                <div class="timeListMainTitle">上映時間</div>
                <div class="freeNumTitle">
                    <div class="freeSeatNumTitle">一般席</div>
                    <div class="freeWCNumTitle">車椅子席</div>
                </div>
            </div>
            <div v-if="timeData" class="timeListSect">
                <div v-for="item in timeData" :key="item.program_no">
                    <time-item
                        :reserve_date="reserve_date"
                        :data="item"
                        :selected_program_no="selected_program_no"
                    ></time-item>
                </div>
            </div>
        </div>
        <input type="hidden" name="program_no" id="program_no" value="0" />
    </div>
</template>

<script>
import TimeListItem from "../components/TimeListItem.vue";
export default {
    // Initilize data
    components: {
        "time-item": TimeListItem
    },
    data() {
        return {
            reserve_date: $("input[name='reserve_date']").val(),
            timeData: "",
            selected_program_no:  $("input[name='program_no']").val(),
            selected_program_name:  $("input[name='selected_program_name']").val(),
        };
    },

    computed: {
        peoples: {
            get() {
                return this.$store.state.data.peoples;
            }
        }
    },

    created() {
        this.getTimeList();
    },

    watch: {
        reserve_date: function() {
            this.getTimeList();
        }
    },

    // Define methods
    methods: {
        getTimeList() {
            let url = `/reserve/new_reservation`;
            let formData = new FormData();
            let header = {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("value")
            };
            formData.append("reserve_date", this.reserve_date);

            this.axios
                .post(url, formData, header)
                .then(response => {
                    this.timeData = response.data;
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }
};
</script>

<style></style>
