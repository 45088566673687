import "babel-polyfill";

window.Vue = require("vue");
import Vue from "vue";
import store from "@/src/store";

import VueAxios from "vue-axios";
import axios from "axios";

Vue.use(VueAxios, axios);
Vue.component("app-component", require("./App.vue").default);

var app = null;

// Declare var because It will be change after calendar pick
document.addEventListener("DOMContentLoaded", async function(event) {
    app = new Vue({
        el: "#app",
        store
    });
});

// Calendar pick
$(function() {
    // Set default selected day
    $(".swiper-slide-active").addClass("selected-day");

    $(".date_content.date_comment_item").click(function(e) {
        e.preventDefault();
        return false;
    });

    $(".date_content").click(function(e) {
        e.preventDefault();
        var selected_day_wrap = $(this);
        if (!$(this).hasClass("date_comment_item")) {
            $(".date_content.selected-day").removeClass("selected-day");
            selected_day_wrap.addClass("selected-day");

            let selected_day = selected_day_wrap.children("input").val();

            $("input[name='reserve_date']").val(selected_day);

            // Set reservate time to time list components
            app.$refs.appchild.$refs.timelist.reserve_date = selected_day;
        }
    });
});
