<template>
    <div>
        <div class="peopleSelectArea">
            <div v-if="peoples" class="commonWidth">
                <div class="peoSelectTitle">
                    ご予約人数
                    <div class="subpeoSelectTitle">1回の予約は4名様まで</div>
                </div>
                <div id="sumOfPeopleSelectArea">
                    <div class="peopselectMainArea">
                        <div class="adultSelection">
                            <span class="ml-2">大人</span><span class="childSelectionNote"
                                >(18歳以上)</span
                            ><br />

                            <div class="selectionDrop">
                                <div class="selectionDropWrapper">
                                    <select
                                        class="selection checkData"
                                        name="num_of_adults"
                                        id="num_of_adults"
                                        v-model="peoples.adults"
                                    >
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                                名様
                            </div>
                        </div>
                        <div class="childSelection">
                            <span class="ml-1">子供</span
                            ><span class="childSelectionNote"
                                >(4歳以上のお⼦さま)</span
                            >
                            <br />
                            <div class="selectionDrop">
                                <div class="selectionDropWrapper">
                                    <select
                                        class="selection checkData"
                                        name="num_of_children"
                                        id="num_of_children"
                                        v-model="peoples.childs"
                                    >
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                                名様
                            </div>
                        </div>
                        <input
                            id="num_of_people"
                            type="hidden"
                            name="num_of_people"
                            :value="peoples.num_of_people"
                        />
                    </div>
                    <div class="peopselectMainAreaSub">
                        ・3歳以下のお子様は大人の方と同席にてご利用ください。
                    </div>
                    <div class="strLine"></div>
                </div>

                <div class="wheelchairArea">
                    <div class="wheelchairAreaL">
                        <img src="/images/wheelchair.svg" alt="" />
                        車椅子をご利用される方は<br />
                        こちらをタップしてください
                    </div>
                    <div class="wheelchairAreaR">
                        <label
                            class="containercbox wheelchairAreaChild"
                            for="expandwhellChair"
                        >
                            <input
                                class="checkData"
                                id="expandwhellChair"
                                type="checkbox"
                                data-toggle="collapse"
                                data-target="#expand_whellChair"
                                v-model="peoples.isWheel"
                            />
                            <span class="checkmark boder-ration-3"></span>
                            車椅子を<br />
                            利用する
                        </label>
                    </div>

                    <div
                        id="expand_whellChair"
                        class="expand_whellChair collapse"
                    >
                        <div class="expand_whellChairL">
                            車椅子をご利用される人数<br />
                            <div class="selectionDrop">
                                <div class="selectionDropWrapper">
                                    <select
                                        class="selection checkData"
                                        name="num_of_wheelchair"
                                        id="num_of_wheelchair"
                                        v-model="peoples.wheelchairs"
                                    >
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                                名様
                            </div>
                        </div>
                        <div class="expand_whellChairR">
                            付き添いされる人数<br />
                            <div class="selectionDrop">
                                <div class="selectionDropWrapper">
                                    <select
                                        class="selection checkData"
                                        name="num_of_attendant"
                                        id="num_of_attendant"
                                        v-model="peoples.attendant"
                                    >
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                                名様
                            </div>
                        </div>
                        <div class="expand_whellChairNote">
                            ※
                            車椅子席をご用意できるのは各回4席までとなっております。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <time-list ref="timelist" />
    </div>
</template>

<script>
import TimeList from "@/src/components/TimeList";
export default {
    data() {
        return {
            peoples: {
                adults: 0,
                childs: 0,
                isWheel: false,
                wheelchairs: 0,
                attendant: 0,
                num_of_people: 0,
                num_of_wheelchairs: 0
            }
        };
    },

    components: {
        "time-list": TimeList
    },

    watch: {
        peoples: {
            handler() {
                if (this.peoples.isWheel === true) {
                    this.peoples.adults = 0;
                    this.peoples.childs = 0;
                    $("#sumOfPeopleSelectArea").css("display", "none");
                    $("#expand_whellChair").css("display", "flex");
                }

                if (this.peoples.isWheel === false) {
                    this.peoples.wheelchairs = 0;
                    this.peoples.attendant = 0;
                    $("#sumOfPeopleSelectArea").css("display", "block");
                    $("#expand_whellChair").css("display", "none");
                }

                // if (this.peoples.num_of_people > 4) {
                //     this.peoples.adults = 0;
                // }

                // if (this.peoples.num_of_wheelchairs > 4) {
                //     this.peoples.wheelchairs = 0;
                // }

                this.peoples.num_of_people = Number(this.peoples.adults);
                this.peoples.num_of_wheelchairs =
                    Number(this.peoples.wheelchairs) +
                    Number(this.peoples.attendant);

                // this.peoples.num_of_people = Number(this.peoples.adults);
                // this.peoples.num_of_wheelchairs = Number(
                //     this.peoples.wheelchairs
                // );
            },
            deep: true
        }
    },
    created() {
        this.peoples.num_of_people = Number(this.peoples.adults);
        this.peoples.num_of_wheelchairs = Number(this.peoples.wheelchairs);
        this.disPatchPeoples("data/setPeoples");
    },
    methods: {
        disPatchPeoples(action, data) {
            this.$store.dispatch(action, this.peoples);
        }
    }
};
</script>

<style></style>
