// initize State
const initState = {
    adults: 0,
    attendant: 0,
    childs: 0,
    isWheel: false,
    num_of_wheelchairs: 0,
    num_of_people: 0
};

//  State
const state = {
    peoples: {
        initState
    },
    selected_program_no: 0,
    selected_program_name: '',
    setTimeDuration: "",
    submitStatus: false
};

// Getters
const getters = {};

//Actions
const actions = {
    // This action will be call in components
    // In Components:
    // this.$store.dispatch("data/setPeoples", {
    //     <Object>
    // });

    setPeoples({ commit }, payload) {
        commit("UpdatePeoples", payload);
    },

    clearPeoples({ commit }, payload) {
        commit("clear", payload);
    },

    // Set program no
    setProgramNo({ commit }, payload) {
        commit("setProgramNo", payload);
    },

    setProgramName({ commit }, payload) {
        commit("setProgramName", payload);
    },

    setTimeDuration({ commit }, payload) {
        commit("setTimeDuration", payload);
    },

    setSubmitStatus({ commit }, payload) {
        commit("setSubmitStatus", payload);
    }
};

const mutations = {
    UpdatePeoples(state, payload) {
        state.peoples = payload;
    },

    clear(state) {
        state.peoples = initState;
    },
    setProgramNo(state, payload) {
        state.selected_program_no = payload;
    },
    setProgramName(state, payload) {
        state.selected_program_name = payload;
    },
    setTimeDuration(state, payload) {
        state.setTimeDuration = payload;
    },
    setSubmitStatus(state, payload) {
        state.submitStatus = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
