<template>
    <div v-if="data" class="timeListSectItem" :class="{ disabled: isDisable }">
        <div :class="[data.program_name != '' && 'p-25-5-3']">
            <!-- プログラム名を追加 -->
            <h4>{{ data.program_name }}</h4>
            <div class="timeListSectItemInfo">
                <div class="timeListSectItemTime">
                    <span class="startTime">{{ data.start_time }}</span>〜
                    {{ data.end_time }}
                </div>

                <SeatStatusItem class="freeSeatNumStatus" type="seat" :only_today="data.only_today" :remain_seat_num="data.free_seat_num" :remain_wc_num="data.free_wc_num" :reserve_disable="data.reserve_disable" class_name="" />
                <SeatStatusItem class="freeWCNumStatus" type="wheelchair" :only_today="data.only_today" :remain_seat_num="data.free_seat_num" :remain_wc_num="data.free_wc_num" :reserve_disable="data.reserve_disable" class_name="" />

            </div>
        </div>
        <input
            class="timeListSectItemSelect"
            :id="data.program_no"
            type="submit"
            value="選択"
            :onclick="getProgramNo"
            :disabled="isDisable == true"
        >
        <img src="/images/link-mark.svg" alt=">" />
    </div>
</template>

<script>
import SeatStatusItem from './SeatStatusItem.vue';

export default {
    components: {
        SeatStatusItem
    },
    props: ["data", "reserve_date", "selected_program_no"],
    data() {
        return {
            isDisable: Boolean($("input[name='is_disabled']").val()),
            isWCDisable: false
        };
    },
    created() {
        this.checkDisabled();
    },
    watch: {
        peoples: {
            handler() {
                this.checkDisabled();
            },
            deep: true
        },
        data: function() {
            this.checkDisabled();
        },
        reserve_date: {
            handler() {
                this.checkDisabled();
            },
            deep: true
        }
    },

    computed: {
        getProgramNo() {
            let content =
                "document.getElementById('program_no').value=" +
                this.data.program_no;
            return content;
        },
        peoples: {
            get() {
                return this.$store.state.data.peoples;
            }
        }
    },
    methods: {
        checkDisabled() {
            this.peoples.adults = Number(this.peoples.adults);
            this.peoples.childs = Number(this.peoples.childs);
            this.peoples.wheelchairs = Number(this.peoples.wheelchairs);
            this.peoples.attendant = Number(this.peoples.attendant);

            if (
                this.peoples.adults == 0 &&
                this.peoples.childs == 0 &&
                this.peoples.wheelchairs == 0 &&
                this.peoples.attendant == 0
            ) {
                // 合計人数が０
                this.isDisable = true;
            } else if (
                this.peoples.adults + this.peoples.childs > 4 ||
                this.peoples.adults + this.peoples.childs > this.data.free_seat_num ||
                (this.peoples.adults + this.peoples.childs > 0 &&
                    this.data.free_seat_num === 0)
            ) {
                // 一般席は 5以上、空きシート数以上
                this.isDisable = true;
            } else if (
                this.peoples.wheelchairs + this.peoples.attendant > 4 ||
                this.peoples.wheelchairs + this.peoples.attendant > this.data.free_wc_num ||
                (this.peoples.wheelchairs + this.peoples.attendant > 0 &&
                    this.data.free_wc_num === 0)
            ) {
                // 車いす席が 5以上、空きシート数以上
                this.isDisable = true;
            } else if (this.data.reserve_disable) {
                this.isDisable = true;
            } else if (this.data.only_today === 1) {
                // 当日予約のみ
                this.isDisable = true;
            } else {
                this.isDisable = false;
            }

        },
    }
};
</script>

<style></style>
