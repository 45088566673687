<template>
    <div v-if="this.status != ''" :class="this.class_name">
        <!-- Normal seat -->
        <div v-if="this.type == 'seat'" class="seatStatusItem">
            <div v-if="this.only_today == 1" class="timeListSectItemStatus">
                <img src="/images/unavailable.svg" alt="予約不可" />
                <h5>当日予約<br/>のみ</h5>
            </div>

            <div v-else-if="this.status === 'ok'" class="">
                <img src="/images/ok.svg" alt="空席あり" />
                <h5>空席あり</h5>
            </div>

            <div v-else-if="this.status === 'few'" class="">
                <img src="/images/few.svg" alt="残りわずか" />
                <h5>
                    残り{{ this.remain_seat_num }}席
                </h5>
            </div>

            <div v-else-if="this.status === 'full'" class="">
                <img src="/images/full.svg" alt="空席なし" />
                <h5>空席なし</h5>
            </div>

            <div v-else-if="this.status === 'unavailable'" class="">
                <img src="/images/unavailable.svg" alt="予約不可" />
                <h5 v-if="this.is_overTime === true">受付終了</h5>
                <h5 v-else>予約不可</h5>
            </div>
        </div>

        <!-- Wheelchair -->
        <div v-else-if="this.type == 'wheelchair'" class="seatStatusItem">
            <div v-if="this.only_today == 1" class="timeListSectItemStatus">
                <img src="/images/unavailable.svg" alt="予約不可" />
                <h5>当日予約<br/>のみ</h5>
            </div>

            <div v-else-if="this.status === 'ok'" class="">
                <img src="/images/ok.svg" alt="空席あり" />
                <h5>空席あり</h5>
            </div>

            <div v-else-if="this.status === 'few'" class="">
                <img src="/images/ok.svg" alt="残りわずか" />
                <h5>残り{{ this.remain_wc_num }}席</h5>
            </div>
            <div v-else-if="this.status === 'few2'" class="">
                <img src="/images/few.svg" alt="残りわずか" />
                <h5>残り{{ this.remain_wc_num }}席</h5>
            </div>

            <div v-else-if="this.status === 'full'" class="">
                <img src="/images/full.svg" alt="空席なし" />
                <h5>空席なし</h5>
            </div>

            <div v-else-if="status === 'unavailable'" class="" >
                <img src="/images/unavailable.svg" alt="予約不可" />
                <h5 v-if="this.is_overTime === true">受付終了</h5>
                <h5 v-else>予約不可</h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["type", "only_today", "remain_seat_num", "remain_wc_num", "reserve_disable", "is_overTime", "class_name"],
    data() {
        return {
            status: "",
        };
    },
    watch: {
        '$props':{
            handler: function (newVal, oldVal) {
                this.setStatus();
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        setStatus() {

            if (this.type === "seat") {
                // Check seat status
                if (this.remain_seat_num === 0) {
                    this.status = "full";
                } else if (this.remain_seat_num > 10) {
                    this.status = "ok";
                } else if (this.remain_seat_num <= 10 && this.remain_seat_num > 0) {
                    this.status = "few";
                }

            } else if (this.type === "wheelchair") {
                // Check WC Status
                if (this.remain_wc_num === 0) {
                    this.status = "full";
                } else if (this.remain_wc_num > 3) {
                    this.status = "ok";
                } else if (this.remain_wc_num === 3) {
                    this.status = "few";
                } else if (this.remain_wc_num <= 2 && this.remain_wc_num > 0) {
                    this.status = "few2";
                }
                
                if (this.remain_seat_num === 0) {
                    // 一般席がfull の場合、車いす席もfull にする
                    this.status = "full";
                }
            }

            // console.log(this.is_overTime);

            if (this.reserve_disable || this.is_overTime) {
                this.status = "unavailable";
            }
        }
    }
};
</script>

<style>
    .seatStatusItem {
        text-align: center;
        padding: 2px 0;
    }

    .seatStatusItem img {
        position: relative;
        z-index: 1;
        height: 25px;
        width: auto;
        margin-bottom: 4px;
    }

    .seatStatusItem h5 {
        font-size: 10px;
        line-height: 13px;
        margin-bottom: 0;
    }
</style>
